import React from 'react';

import { Tracking } from 'keme-react';


export const cookies = {
  partners: {'marketing': [Tracking.Partner.GoogleAnalytics]},
  title: () => (<p>Diese Webseite verwendet Cookies</p>),
  description: () => (<p>Wir verwenden Cookies, um Dir ein bestmögliches Online-Erlebnis zu gewährleisten. Dies umfasst die Personalisierung von Inhalten und Anzeigen, die Optimierung der Funktionen für soziale Medien sowie die Analyse der Zugriffe auf unserer Website. Weiterhin stellen wir Informationen zu Deiner Verwendung unserer Website unseren Partnern für soziale Medien, Werbung und Analysen zur Verfügung. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Du ihnen bereitgestellt hast oder die sie im Rahmen Deiner Nutzung der Dienste gesammelt haben.
Entscheide bitte selbst, welche Cookies Du akzeptierst.</p>),
  about_cookies: () => (
    <React.Fragment>
      <p>Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um das Online-Erlebnis zu analysieren und zu optimieren.</p>
      <p>Laut DSGVO können wir Cookies auf Deinem Gerät speichern, wenn diese für die Funktionalität der Webseite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Deine Erlaubnis.</p>
      <p>Unsere Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.</p>
      <p>Du kannst jederzeit Deine Einwilligung von der Cookie-Erklärung auf unserer Website ändern oder widerrufen.</p>
      <p>In unserer Datenschutzrichtlinie erfährst Du mehr darüber, wer wir sind, wie Du mit uns Kontakt aufnehmen kannst und wie wir personenbezogene Daten verarbeiten.</p>
      <p>Deine Einwilligung trifft auf die folgenden Domains zu: premiumshop24.de, www.premiumshop24.de, api.ps30.de, images.ps30.de</p>
    </React.Fragment>
  ),
  groups: [
    {
      key: 'essential',
      title: 'Notwendig',
      required: true,
      description: () => (<p>Notwendige Cookies unterstützen die Funktionalität einer Website, indem sie grundlegende Funktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies leider nicht funktionieren.</p>),
      cookies: [
        {
          name: 'cookie-consent',
          provider: 'Premiumshop24',
          purpose: 'Speichert den Zustimmungsstatus des Benutzers für Cookies auf der aktuellen Domäne.',
          expire: '1 Jahr',
          type: 'HTTP'
        },
        {
          name: '__RequestVerificationToken',
          provider: 'Premiumshop24',
          purpose: 'Hilft, Cross-Site Request Forgery- (CSRF-) Angriffe zu verhindern.',
          expire: 'Session',
          type: 'HTTP'
        },
        {
          name: '__stripe_mid ',
          provider: 'Premiumshop24',
          purpose: 'Dieser Cookie ist notwendig, um Kreditkartentransaktionen auf der Webseite durchzuführen. Der Dienst wird von Stripe.com bereitgestellt, das Online-Transaktionen ermöglicht, ohne dass Kreditkarteninformationen gespeichert werden .',
          expire: '1 Jahr',
          type: 'HTTP'
        },
        {
          name: '__stripe_sid ',
          provider: 'Premiumshop24',
          purpose: 'Dieser Cookie ist notwendig, um Kreditkartentransaktionen auf der Webseite durchzuführen. Der Dienst wird von Stripe.com bereitgestellt, das Online-Transaktionen ermöglicht, ohne dass Kreditkarteninformationen gespeichert werden .',
          expire: '1 Tag',
          type: 'HTTP'
        },
        {
          name: 'https://q.stripe.com/ ',
          provider: 'q.stripe.com',
          purpose: 'Dieser Cookie ist notwendig, um Kreditkartentransaktionen auf der Webseite durchzuführen. Der Dienst wird von Stripe.com bereitgestellt, das Online-Transaktionen ermöglicht, ohne dass Kreditkarteninformationen gespeichert werden .',
          expire: 'Session',
          type: 'Pixel'
        },
        {
          name: 'm',
          provider: 'm.stripe.com',
          purpose: 'Bestimmt das Gerät, mit dem auf die Webseite zu gegriffen wird. Dadurch kann die Webseite entsprechend formatiert werden .',
          expire: 'Session',
          type: 'Pixel'
        },
        {
          name: 'nchan-id ',
          provider: 'Premiumshop24',
          purpose: '',
          expire: 'Pesistent',
          type: 'HTML'
        },
        {
          name: 'ps30-token ',
          provider: 'Premiumshop24',
          purpose: 'Dieser dient lediglich dazu, mehrere Anfragen eines Nutzers auf einer Seite dessen Sitzung zuzuordnen. So kann der Nutzer identifiziert werden und bestimmte Präferenzen oder Einstellungen – zum Beispiel einen Online-Warenkorb – speichern und abrufen.',
          expire: 'Session',
          type: 'HTTP'
        }
      ]
    },
    {
      key: 'marketing',
      title: 'Marketing',
      description: () => (<p>Unsere Marketing-Cookies verfolgen das Ziel, Besuchern auf unserer Webseite zu folgen. Die Absicht dahinter ist, Anzeigen zu zeigen, die relevant und ansprechend für Dich als Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.</p>),
      cookies: [
        {
          name: '__RequestVerificationToken',
          provider: 'Premiumshop24',
          purpose: 'Hilft, Cross-Site Request Forgery- (CSRF-) Angriffe zu verhindern.',
          expire: 'Session',
          type: 'HTTP'
        },
        {
          name: 'tr',
          provider: 'Facebook',
          purpose: 'Wird von Facebook genutzt, um eine Reihe von Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote dritter Werbetreibender.',
          expire: 'Session',
          type: 'Pixel'
        },
        {
          name: 'GPS',
          provider: 'youtube.com',
          purpose: 'Registriert eine eindeutige ID auf mobilen Geräten, um Tracking basierend auf dem geografischen GPS-Standort zu ermöglichen.',
          expire: '1 Tag',
          type: 'HTTP'
        },
        {
          name: 'IDE',
          provider: 'doubleclick.net',
          purpose: 'Verwendet von Google DoubleClick, um die Handlungen des Benutzers auf der Webseite nach der Anzeige oder dem Klicken auf eine der Anzeigen des Anbieters zu registrieren und zu melden , mit dem Zweck der Messung der Wirksamkeit einer Werbung und der Anzeige zielgerichteter Werbung für den Benutzer.',
          expire: '1 Jahr',
          type: 'HTTP'
        },
        {
          name: 'test_cookie',
          provider: 'doubleclick.net',
          purpose: 'Verwendet, um zu überprüfen , ob der Browser des Benutzers Cookies unterstützt.',
          expire: '1 Tag',
          type: 'HTTP'
        },
        {
          name: 'VISITOR_INFO1_LIVE',
          provider: 'youtube.com',
          purpose: 'Versucht, die Benutzerbandbreite auf Seiten mit integrierten YouTube-Videos zu schätzen .',
          expire: '179 Tage',
          type: 'HTTP'
        },
        {
          name: 'YSC',
          provider: 'youtube.com',
          purpose: 'Registriert eine eindeutige ID, um Statistiken der Videos von YouTube, die der Benutzer gesehen hat, zu behalten .',
          expire: 'Session',
          type: 'HTTP'
        },
        {
          name: 'yt-remote-cast-installed',
          provider: 'youtube.com',
          purpose: 'Speichert die Benutzereinstellungen beim Abruf eines auf anderen Webseiten integrierten YoutubeVideos.',
          expire: 'Session',
          type: 'HTML'
        },
        {
          name: 'yt-remote-connected-devices',
          provider: 'youtube.com',
          purpose: 'Speichert die Benutzereinstellungen beim Abruf eines auf anderen Webseiten integrierten YoutubeVideos.',
          expire: 'Persisent',
          type: 'HTML'
        },
        {
          name: 'yt-remote-device-id',
          provider: 'youtube.com',
          purpose: 'Speichert die Benutzereinstellungen beim Abruf eines auf anderen Webseiten integrierten YoutubeVideos.',
          expire: 'Persisent',
          type: 'HTML'
        },
        {
          name: 'yt-remote-fast-check-period',
          provider: 'youtube.com',
          purpose: 'Speichert die Benutzereinstellungen beim Abruf eines auf anderen Webseiten integrierten YoutubeVideos.',
          expire: 'Session',
          type: 'HTML'
        },
        {
          name: 'yt-remote-session-app',
          provider: 'youtube.com',
          purpose: 'Speichert die Benutzereinstellungen beim Abruf eines auf anderen Webseiten integrierten YoutubeVideos.',
          expire: 'Session',
          type: 'HTML'
        },
        {
          name: 'yt-remote-session-name',
          provider: 'youtube.com',
          purpose: 'Speichert die Benutzereinstellungen beim Abruf eines auf anderen Webseiten integrierten YoutubeVideos.',
          expire: 'Session',
          type: 'HTML'
        }
      ]
    },
  ],
};
