import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import packageJson from '../package.json';


import { Demo } from 'ps30-react';


import './AppCss';
import { config } from './Config';

import common_de_du from "ps30-assets/translations/de_du/common.json";
import common_de_sie from "ps30-assets/translations/de_sie/common.json";

import {StripeProvider} from 'react-stripe-elements';

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import { Tracking, Nchan, Jwt } from 'keme-react';

import { ErrorBoundary } from 'keme-react';

import { Block } from 'ps30-react';
import LocalBlock from './Component/System/LocalBlock';
import Maintenance from './Component/System/Maintenance';
import ErrorPage from './Component/System/ErrorPage';


const AreaHome = React.lazy(() => import('./Area/Home'))
const AreaProduct = React.lazy(() => import('./Area/Product'))
const AreaCategory = React.lazy(() => import('./Area/Category'))
const AreaPage = React.lazy(() => import('./Area/Page'))
const AreaAccount = React.lazy(() => import('./Area/Account'))
const AreaCheckout = React.lazy(() => import('./Area/Checkout'))
const AreaSearch = React.lazy(() => import('./Area/Search'))


const BrowserWarning = React.lazy(() => import('./Component/System/BrowserWarning'))



global.appVersion = packageJson.version;

Block.setElement(LocalBlock);
const localStorageLang = localStorage.getItem('lng');
const defaultLang = 'de_du'
i18next.init({
    lng: localStorageLang ? localStorageLang : defaultLang,
    debug: false,
    resources: {
      de_du: {
        translation: common_de_du
      },
      de_sie: {
        translation: common_de_sie
      },
    }
});





const Routes = () => {
  return (
    <div>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/search/:query" component={AreaSearch} />
          <Route path="/checkout" component={AreaCheckout} />
          <Route path="/pa/:slug" component={AreaPage} />
          <Route path="/p/:slug" component={AreaProduct} />
          <Route path="/c/:slug+/merkmale/:merkmale+" component={AreaCategory} />
          <Route path="/c/:slug+" component={AreaCategory} />
          <Route path="/account" component={AreaAccount} />
          <Route path="/" component={AreaHome} />
        </Switch>
        <BrowserWarning />
      </Suspense>
    </div>
  );
}

const App = () => {
  const appStage = process.env.REACT_APP_STAGE;
  console.log(appStage);
  return (
    <div className="App">
      <Router basename={config.general.basename}>
        <Routes />
      </Router>
      {appStage != 'prod' ? <Demo bottom={true} /> : ''}
    </div>
  );
}

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster = (props) => {
  const clearCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  }

  Nchan.useEvent('app_version_increase', (event) => {
    clearCacheAndReload()
  });

  React.useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          console.log('Clearing cache and hard reloading...')
          clearCacheAndReload();
        }
      });
  })
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}




function Wrapper() {
  const [token, setToken] = React.useState(localStorage.getItem('token') || false);

  return (
    <Nchan.Provider>
      <I18nextProvider i18n={i18next}>
        <CacheBuster>
          <ErrorBoundary component={ErrorPage}>
            <Tracking.Provider options={config.cookies}>
              <StripeProvider apiKey={config.general.stripe_public_key} >
                <App />
              </StripeProvider>
              <Tracking.Banner/>
            </Tracking.Provider>
            <Maintenance />
          </ErrorBoundary>
        </CacheBuster>
      </I18nextProvider>
    </Nchan.Provider>
  );

}

export default Wrapper;
