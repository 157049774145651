import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import PropTypes from "prop-types"


import { GET_BLOCK } from '../../Graphql/query';

import TrackVisibility from 'react-on-screen';


const Block = (props) => {
  const { loading, error, data } = useQuery(GET_BLOCK, {
    variables: {name: props.name}
  });


  if(loading && props.alt) {
    let Content = props.alt;
    return (
      <React.Fragment>
        <div {...{className: props.className, style: props.style}}><Content /></div>
        <div style={{display: 'none'}}>{props.name}</div>
      </React.Fragment>
    )
  }

  if(loading) return <div>Loading</div>;
  if(error) return <div>Error! ${error.message}</div>;

  let blockContent

  if(!data.block) {
    if(props.alt) {
      let Content = props.alt;
      return (
        <React.Fragment>
          <div {...{className: props.className, style: props.style}}><Content /><span style={{display: 'none'}}>{props.name}</span></div>
          <div style={{display: 'none'}}>{props.name}</div>
        </React.Fragment>
      )
    }
    blockContent = {__html: `404<span style="display:none">"${props.name}"</span>`};
  } else {
    blockContent = {__html: data.block.content};
  }

  let style = {};
  if(props.style) style = props.style;

  return (
    <React.Fragment>
      <div {...{className: props.className, style: props.style}} dangerouslySetInnerHTML={blockContent} />
      <div style={{display: 'none'}}>{props.name}</div>
    </React.Fragment>
  )
}

const Wrapper = (props) => {
  if(props.isVisible) return <Block {...props} />
  return <span></span>;
}

const Outer = (props) => {
  if(props.lazy === false) {
    return <Block {...props} />;
  }

  return (
    <TrackVisibility once offset={200}>
      <Wrapper {...props} />
    </TrackVisibility>
  );
}


export default Outer;
