import { gql } from 'apollo-boost';

export const GET_BLOCK = gql`
  query getBlock($name: String!) {
    block(name: $name) {
      name
      content
    }
  }
`;

export const GET_DYNAMIC_BLOCK = gql`
  query getDynamicBlock($slug: String!) {
    dynamic_block(slug: $slug) {
      id
      slug
      data
    }
  }
`;

export const GET_PAGE = gql`
  query page($slug: String!) {
    page(slug: $slug) {
      id
      slug
      title
      content
      description
      keywords
    }
  }
`;

export const GET_SLIDER = gql`
  query slider($code: String!) {
    slider(code: $code) {
      code
      banners {
        id
        title
        image
        imageResponsive
        target
      }
    }
  }
`;

export const SEARCH_SIMPLE = gql`
  query product_search_simple($search: String!) {
    product_search_simple(search: $search) {
      id
      type
      score
      distance
      category {
        name
        slug
      }
      product {
        name
        slug
        attributes {
          code
          value
        }
      }
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      id
      name
      email
      guest
      user {
        firstname
        lastname
        addresses {
          id
          prefix
          firstname
          lastname
          street
          postcode
          country_id
          telephone
          is_default_billing
          is_default_shipping
        }
      }
    }
  }
`;

export const CART_FRAGMENT = gql`
    fragment CartFields on Cart {
      id
      name
      active
      itemCount
      created_at
      updated_at
      amount
      total
      discountCode {
        code
      }
      cartDiscountAmount
      itemGroups {
        id
        qty
        mainItem {
          id
          qty
          sku
          name
          mainItem
          rowTotal
          origRowTotal
          hasReservation
          reservationValidSeconds
          discount {
            name
          }
          product {
            stockItem {
              available
              allow_preorder
              end_of_life
              stock_product
              delivery_time
              delivery_time_on_request
            }
            attributes {
              code
              value
            }
            images {
              url
            }
          }
          price
          orig_price
        }
        items {
          id
          qty
          sku
          name
          mainItem
          rowTotal
          hasReservation
          reservationValidSeconds
          discount {
            name
          }
          product {
            stockItem {
              available
              allow_preorder
              end_of_life
              stock_product
              delivery_time
              delivery_time_on_request
            }
            attributes {
              code
              value
            }
            images {
              url
            }
          }
          price
        }
      }
    }
  `;

export const GET_CARTS = gql`
  query getCarts {
    carts {
      ...CartFields
    }
  }
  ${CART_FRAGMENT}
`;

export const GET_CART = gql`
  query getCart {
    cart {
      ...CartFields
    }
  }
  ${CART_FRAGMENT}
`;


export const PRODUCT_FRAGMENT = gql`
    fragment ProductFields on Product {
      name
      slug
      sku
      ean
      description
      shortDescription
      stockItem {
        qty
        available
        end_of_life
        allow_preorder
        stock_product
        delivery_time_on_request
      }
      images {
        url
      }
      attributes {
        code
        value
      }
      stroke_price {
        amount
        label
      }

      final_price {
        amount
        label
      }
    }
  `;

export const GET_PRODUCT = gql`
  query getProduct($slug: String!, $sku: String) {
    product(slug: $slug, sku: $sku) {
      ...ProductFields
      categories {
        id
        name
        slug
        parent {
          id
        }
      }
      equipment: related(type: "equipment") {
        id
        sku
        name
        slug
      }
      equipment_for: related(type: "equipment_for") {
        id
        sku
        name
        slug
      }
      similar: related(type: "similar") {
        id
        sku
        name
        slug
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_SETS = gql`
  query getProductSets($parent_slug: String!) {
    product_sets(parent_slug: $parent_slug) {
      name
      slug
      final_price {
        amount
        label
      }
      stroke_price {
        amount
        label
      }
      parentProduct {
        slug
      }
      products {
        slug
      }

    }
  }
`;

export const GET_CATEGORY = gql`
  query getCategory($slug: String!) {
    category(slug: $slug) {
      name
      slug
      design
      meta_description
      ambienceImage
      tileImage
      dynamicContentBlock
      parent {
        name
        slug
      }
      children {
        name
        slug
        children {
          name
          slug
        }
      }
    }
    category_smeg_klein(slug: $slug) {
      categoryProductImage
      categoryAmbienceImage
      filter {
        attribute
        default
        tab_type
        images {
          value
          url
        }
        values
      }
      products {
        ...ProductFields
      }
    }
    category_eggs(slug: $slug) {
      products {
        ...ProductFields
      }
    }
    category_list(slug: $slug) {
      display_attributes
      filter
      products {
        ...ProductFields
      }
    }
    category_egg_zubehoer(slug: $slug, limit: 1) {
      categoryProductImage
      categoryAmbienceImage
    }
    category_smeg_zubehoer(slug: $slug) {
      categoryProductImage
      categoryAmbienceImage
      filter {
        attribute
        default
        tab_type
        images {
          value
          url
        }
        values
      }
      products {
        ...ProductFields
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_CATEGORY_SMEG_KLEIN = gql`
  query getCategorySmegKlein($slug: String!) {
    category_smeg_klein(slug: $slug) {
      categoryProductImage
      categoryAmbienceImage
      filter {
        attribute
        default
        tab_type
        images {
          value
          url
        }
        values
      }
      products {
        ...ProductFields
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_CATEGORY_SMEG_ZUBEHOER = gql`
  query getCategorySmegZubehoer($slug: String!) {
    category_smeg_zubehoer(slug: $slug) {
      categoryProductImage
      categoryAmbienceImage
      filter {
        attribute
        default
        tab_type
        images {
          value
          url
        }
        names {
          value
          name
        }
        values
      }
      products {
        ...ProductFields
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_CATEGORY_EGG_ZUBEHOER = gql`
  query getCategoryEggZubehoer($slug: String!, $limit: Int) {
    category_egg_zubehoer(slug: $slug, limit: $limit) {
      categoryProductImage
      categoryAmbienceImage
      productCount
      filter {
        attribute
        default
        tab_type
        images {
          value
          url
        }
        names {
          value
          name
        }
        values
      }
      products {
        ...ProductFields
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_CATEGORY_TREE = gql`
  query getCategory {
    categories {
      name
      slug
      design
      children {
        name
        slug
        children {
          name
          slug
        }
      }
    }
  }
`;


export const ADDRESS_FRAGMENT = gql`
  fragment AddressFields on Address {
    id
    prefix
    firstname
    lastname
    street
    city
    postcode
    country_id
    telephone
    is_default_billing
    is_default_shipping
  }
`;


export const GET_CHECKOUT_TICKET = gql`
  query getCheckoutTicket($id: String!) {
    checkout_ticket(id: $id) {
      id
      valid
      email
      guest
      order_id
      cart {
        ...CartFields
      }
      billing_address {
        ...AddressFields
      }
      shipping_address {
        ...AddressFields
      }
      send_to_billing
      shipping_method
      shipping_amount
      shipping_options
      total
      item_total
      discount_amount
      total_tax
      prepared_payment_method
      payment_method
      stripe_client_secret
    }
  }
  ${CART_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const GET_ADDRESSES = gql`
  query getAddresses {
    addresses {
      ...AddressFields
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const GET_SHIPPING_METHODS = gql`
  query getShippingMethods($ticket: String!) {
    shipping_methods(ticket: $ticket) {
      key
      title
      description
      amount
      options {
        key
        amount
        description
        title
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query payment_methods($ticket_id: String!) {
    payment_methods(ticket: $ticket_id) {
      key
      title
      icon
      description
    }
  }
`;

export const GET_MY_ORDERS = gql`
  query my_orders {
    me {
      id
      orders {
        id
        increment_id
        created_at
        guest
        total
        total_tax
      }
    }
  }
`;

export const GET_MY_ORDER = gql`
  query my_order($id: String!) {
    me {
      id
      order(id: $id) {
        id
        email
        increment_id
        created_at
        guest
        send_to_billing
        shipping_method
        shipping_amount
        shipping_options
        total
        total_tax
        payment_method
        comments {
          id
          message
          status
          created_at
        }
        billing_address {
          prefix
          company
          firstname
          lastname
          street
          postcode
          city
          country_id
          telephone
        }
        shipping_address {
          prefix
          company
          firstname
          lastname
          street
          postcode
          city
          country_id
          telephone
        }
        items {
          id
          sku
          name
          qty
          price
          main_item
        }
      }
    }
  }
`;
