import React from 'react';

import BackendVersionContext from './BackendVersionContext';
import styles from './Maintenance.module.scss';

export default () => {
  const {preMaintenance} = React.useContext(BackendVersionContext);



  const [remainingSeconds, setRemainingSeconds] = React.useState(0);

  const updateTime = () => {
    setRemainingSeconds(Math.round(parseInt(preMaintenance) - (Date.now() / 1000), 0));
  }

  React.useEffect(() => {
    updateTime();
  }, [preMaintenance])

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      setRemainingSeconds(Math.round(parseInt(preMaintenance) - (Date.now() / 1000), 0));
    }, 1000);

    return () => {
      window.clearInterval(interval);
    }
  }, [preMaintenance])

  if(preMaintenance) {

    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds - minutes * 60;

    return (
      <div className={styles['outer']}>
        <div className={styles['background']} onClick={() => {
        }}></div>
        <div className={styles['popup']}>
          <h1>Auftragsgriller aufgepasst!</h1>
          <p>Damit du auch weiterhin bei uns auf Deinen Geschmack komst, führen wir <b>in kürze</b> ein <b>notwendiges System-Update</b> durch. Dafür wird unsere Big Green Egg Markenwelt leider <b>ca 15 Minuten nicht erreichbar sein</b></p>
          <p>Aber das warten lohnt sich!</p>
          <p>Verbleibende Zeit: {minutes > 0 ? (minutes + 'min') : ''} {seconds > 0 ? (seconds + 'sec') : ''}</p>
        </div>
      </div>
    );
  }

  return '';

}
