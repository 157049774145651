import React from 'react';

import { useCookies } from 'react-cookie';

const clearCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
}

export default (props) => {
  localStorage.removeItem('checkout_ticket');

  const [cookies, setCookie] = useCookies('error_reload');

  let lastErrorReload = cookies['error_reload'] || (new Date).getTime()

  const offset = 10000;

  let timeSinceLast = (new Date).getTime() - lastErrorReload;

  if(timeSinceLast > offset) {
    setCookie('error_reload', (new Date).getTime());
    clearCacheAndReload();
  }

  return (
    <div style={{width: '100%', maxWidth: '90vw', margin: 'auto', textAlign: 'center'}}>
      <h1>Ups... Leider ist ein Fehler aufgetreten</h1>
      <div>
        <p>Bitte versuchen Sie es erneut. Sollten Sie diese Meldung wieder sehen, nehmen Sie bitte zu unserem Team kontakt auf.</p>
        <a href={'/'}>Zur Startseite</a>
      </div>
    </div>
  );
}
