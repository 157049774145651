import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { ApolloProvider } from '@apollo/react-hooks';

import { config } from './Config';

import { Jwt } from 'keme-react';

import BackendVersionContext from './Component/System/BackendVersionContext';


const ApolloApp = (props) => {
  const {token} = Jwt.useToken();
  const [client, setClient] = React.useState(false);

  React.useEffect(() => {
    let apolloClient = new ApolloClient({
      uri: config.graphql.url,
      //cache,
      request: (operation) => {
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        })
      }
    })
    setClient(apolloClient);
  }, [token]);

  if(!client) return (
    <React.Fragment>

    </React.Fragment>
  );
  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  );
}

const checkLogin = ({setToken, logout, token}, payload) => {
  fetch(config.graphql.login.test, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }).then(response => response.json()).then(data => {
    if(data.success) {
      if(data.version && payload.backendVersion && payload.setBackendVersion && data.version != payload.backendVersion) {
        payload.setBackendVersion(data.version);
      }

      if(payload.setPreMaintenance && data.pre_maintenance != payload.preMaintenance) {
        payload.setPreMaintenance(data.pre_maintenance);
      }
      return;
    }
    logout();
  })
}

const doLogin = (props) => {
  fetch(config.graphql.login.guest)
    .then(response => response.json())
    .then((data) => {
      props.setToken(data.token)
    });
}

const JwtApp = () => {
  const [backendVersion, setBackendVersion] = React.useState('x.y.z');
  const [preMaintenance, setPreMaintenance] = React.useState(false);
  return (
    <Jwt.Provider doLogin={doLogin} checkLogin={checkLogin} checkLoginPayload={{backendVersion, setBackendVersion, setPreMaintenance}} cookieName={'ps30-token'}>
      <BackendVersionContext.Provider value={{backendVersion, setBackendVersion, preMaintenance, setPreMaintenance}}>
        <ApolloApp>
          <App />
        </ApolloApp>
      </BackendVersionContext.Provider>
    </Jwt.Provider>
  );
}



ReactDOM.render(<JwtApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
